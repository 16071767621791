"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.object.to-string.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _UserService = _interopRequireDefault(require("@/api/UserService"));

var _SysUserListService = _interopRequireDefault(require("@/api/SysUserListService"));

var _SysUserList = _interopRequireDefault(require("@/api/entity/SysUserList"));

var _token = require("@/utils/token");

var _loginToken = require("@/utils/loginToken");

var _router = require("@/router");

// import axios from 'axios'
var state = {
  token: (0, _token.getToken)(),
  avatar: 'favicon.ico',
  userId: '',
  userUuid: '',
  userName: '',
  userNickName: '',
  userPhone: '',
  userEmail: '',
  authorityList: [],
  roleList: [],
  userCode: '',
  orgnCode: '',
  orgnName: '',
  orgnId: '',
  sysOrgan: '',
  userLongId: ''
};
var mutations = {
  RESET_STATE: function RESET_STATE(state) {
    state.token = (0, _token.getToken)();
    state.avatar = 'favicon.ico';
    state.userId = '';
    state.userUuid = '';
    state.userName = '';
    state.userNickName = '';
    state.userPhone = '';
    state.userEmail = '';
    state.authorityList = state.authorityList.filter(function () {
      return false;
    });
    state.userCode = '';
    state.roleList = [];
    state.orgnCode = '';
    state.orgnName = '';
    state.orgnId = '';
    state.sysOrgan = '';
    state.userLongId = '';
  },

  /**
   *
   * @param state
   * @param user {User}
   * @constructor
   */
  SET_USER: function SET_USER(state, user) {
    var _user$avatarUrl;

    state.avatar = ((_user$avatarUrl = user.avatarUrl) === null || _user$avatarUrl === void 0 ? void 0 : _user$avatarUrl.url) || 'favicon.ico';
    state.userId = user.id;
    state.userUuid = user.uuid;
    state.userName = user.username;
    state.userNickName = user.nickName;
    state.userPhone = user.phone;
    state.userEmail = user.email;
    state.authorityList = user.authorityList;
    state.userCode = user.userCode;
    state.roleList = user.roleList;
    state.orgnCode = user.orgnCode;
    state.orgnName = user.orgnName;
    state.orgnId = user.orgnId;
    state.sysOrgan = user.sysOrgan;
    state.userLongId = user.userId;
  },

  /**
   *
   * @param state
   * @param token {User}
   * @constructor
   */
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },

  /**
   *
   * @param state
   * @param avatar {FileInfo}
   * @constructor
   */
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = (avatar === null || avatar === void 0 ? void 0 : avatar.url) || 'favicon.ico';
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    (0, _loginToken.removeLoginToken)();
    var userCode = userInfo.userCode,
        passWord = userInfo.passWord;
    return new Promise(function (resolve, reject) {
      _UserService.default.login(userCode, passWord).then(function (result) {
        if (result.isSuccess()) {
          commit('SET_TOKEN', result.data[0].accessToken);
          (0, _token.setToken)(result.code);
          (0, _loginToken.setLoginToken)(result.data[0].accessToken); // result.dataFirst(
          //   data => {
          //     const vo = new User(data)
          //     commit('SET_USER', vo)/user/current
          //     setToken(vo.uuid)
          //   }
          // )

          resolve();
        } else {
          reject(result.message);
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  current: function current(_ref2, state) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      _SysUserListService.default.getCurrentUser((0, _loginToken.getLoginToken)()).then(function (result) {
        if (result.isSuccess()) {
          result.dataFirst(function (data) {
            var vo = new _SysUserList.default(data);
            commit('SET_USER', vo);
            resolve(vo);
          }, function () {
            return reject();
          });
        } else {
          reject(result.message);
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
        dispatch = _ref3.dispatch;
    return new Promise(function (resolve, reject) {
      _UserService.default.logout().then(function () {
        (0, _token.removeToken)(); // must remove  token  first

        (0, _loginToken.removeLoginToken)();
        (0, _router.resetRouter)();
        dispatch('tagsView/delAllViews', null, {
          root: true
        });
        commit('RESET_STATE');
        location.reload();
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      (0, _token.removeToken)(); // must remove  token  first

      (0, _loginToken.removeLoginToken)();
      commit('RESET_STATE');
      resolve();
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;