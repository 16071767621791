"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _UserService = _interopRequireDefault(require("@/api/UserService"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    var _this = this;

    return {
      btnSaveLoading: false,
      row: {
        passwordOld: null,
        passwordNew: null,
        passwordConfirm: null
      },
      rules: {
        passwordOld: [{
          required: true,
          message: '请输入原密码',
          trigger: 'blur'
        }, {
          min: 6,
          max: 15,
          message: '长度在 6 到 15 个字符',
          trigger: 'blur'
        }],
        passwordNew: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }, {
          min: 6,
          max: 15,
          message: '长度在 6 到 15 个字符',
          trigger: 'blur'
        }, {
          validator: function validator(rule, value, callback) {
            if (!value) {
              callback();
            }

            if (/^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,15}$/.test(value)) {
              callback();
            } else {
              callback(new Error('密码必须为6-15位数字加字符,可以携带特殊字符'));
            }
          },
          trigger: 'blur'
        }],
        passwordConfirm: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }, {
          min: 6,
          max: 15,
          message: '长度在 6 到 15 个字符',
          trigger: 'blur'
        }, {
          validator: function validator(rule, value, callback) {
            if (!value) {
              callback();
            }

            if (value === _this.row.passwordNew) {
              callback();
            } else {
              callback(new Error('两次输入密码不一致'));
            }
          },
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    visibleDialog: {
      get: function get() {
        return this.visible;
      },
      set: function set(val) {
        this.$emit('update:visible', val);
      }
    }
  },
  methods: {
    handleCancel: function handleCancel() {
      this.$refs.rowForm.resetFields();
      this.visibleDialog = false;
    },
    handleSave: function handleSave() {
      var _this2 = this;

      this.$refs.rowForm.validate(function (valid) {
        if (valid) {
          _this2.btnSaveLoading = true;

          _UserService.default.changePassword(_this2.row.passwordOld, _this2.row.passwordNew).then(function (result) {
            _this2.btnSaveLoading = false;
            result.hasFailure(function () {
              return _this2.$message.warning(result.getErrorMessage('密码修改失败'));
            }).hasSuccess(function () {
              _this2.$message.success('密码修改成功');

              _this2.handleCancel();

              _this2.$emit('ok');
            });
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;