"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.number.constructor.js");

require("core-js/modules/es.string.includes.js");

require("core-js/modules/es.string.trim.js");

require("core-js/modules/web.dom-collections.for-each.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _typeof2 = _interopRequireDefault(require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/typeof"));

var _MaterialConfigService = _interopRequireDefault(require("@/api/MaterialConfigService"));

var _convertImgUrlPrefix2 = _interopRequireDefault(require("@/utils/convertImgUrlPrefix"));

var _svgaplayerweb = _interopRequireDefault(require("svgaplayerweb"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 自定义指令，用于处理点击外部区域的事件
var clickOutside = {
  bind: function bind(el, binding) {
    // 在元素上绑定一个点击事件监听器
    el.clickOutsideEvent = function (event) {
      // 检查点击事件是否发生在元素的内部
      if (!(el === event.target || el.contains(event.target))) {
        // 如果点击事件发生在元素的外部，则触发指令绑定的方法,将点击的event数据传过去
        binding.value(event);
      }
    }; // 在文档上添加点击事件监听器


    document.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function unbind(el) {
    // 在元素上解除点击事件监听器
    document.removeEventListener("click", el.clickOutsideEvent);
  }
};
var _default2 = {
  directives: {
    "click-outside": clickOutside // 注册自定义指令

  },
  components: {},
  props: {
    // type: {
    //   type: Number,
    //   required: false,
    // },
    value: {
      type: String,
      default: function _default() {
        return "";
      }
    },
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isDetail: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    index: {
      type: Number,
      required: true
    }
  },
  data: function data() {
    return {
      searchName: "",
      checkedList: [],
      initCheckedList: [],
      // selectTitle: null,
      selectValue: "",
      drownShow: false,
      selectUrlRow: {}
    };
  },
  computed: {
    convertImgUrlPrefix: function convertImgUrlPrefix() {
      return function (url) {
        return (0, _convertImgUrlPrefix2.default)(url);
      };
    },
    selectPreview: function selectPreview() {
      return function (val) {
        var result = "";
        this.checkedList.map(function (item) {
          if (item.secUuid == val) {
            result = item.label;
          }
        });
        return result;
      };
    }
  },
  watch: {
    value: function value(val, oldVal) {
      if (val != oldVal) {
        this.selectValue = val;
        this.getModelData();
      }
    },
    list: function list(val, oldVal) {
      if (val != oldVal) {
        this.selectValue = this.value;
        this.getModelData();
      }
    }
  },
  methods: {
    // 权益礼品搜索
    searchNameChange: function searchNameChange(val) {
      var afVal = val.trim() || "";

      if (afVal && afVal != "") {
        this.checkedList = JSON.parse(JSON.stringify(this.initCheckedList));
        this.checkedList = this.checkedList.filter(function (item) {
          return item.label.includes(afVal);
        });
      } else {
        this.getModelData();
      }
    },
    // 获取下拉列表内容
    getModelData: function getModelData() {
      var _this = this;

      this.checkedList = this.list.map(function (row, index) {
        if (row.videoUrl) {
          var strType = (0, _typeof2.default)(row.videoUrl);

          if (strType == 'string') {
            row.videoUrl = JSON.parse(row.videoUrl);
          }
        }

        if (_this.isDetail) {
          if (_this.selectValue == row.secUuid) {
            _this.selectUrlRow = row;
          }
        }

        if (row.videoUrl && row.videoUrl.url && row.videoUrl.url.includes('svg')) {
          row.svgaId = 'svga' + _this.index + '' + index;

          _this.playFunction(row.videoUrl.url, row.svgaId);
        }

        return row;
      });
      this.initCheckedList = JSON.parse(JSON.stringify(this.checkedList));
      this.lightItem();

      if (this.selectUrlRow.videoUrl && this.selectUrlRow.videoUrl.url && this.selectUrlRow.videoUrl.url.includes('svg')) {
        this.playFunction(this.selectUrlRow.videoUrl.url, 'svgaId');
      }
    },
    // 展开收起触发事件
    visibleChange: function visibleChange(val) {
      this.drownShow = val;

      if (val == true) {
        this.lightItem();
      }
    },
    // 下拉框项点击事件
    handleCommand: function handleCommand(command) {
      this.selectValue = command.secUuid;
      this.lightItem();
      this.$emit("selected", command.secUuid);
    },
    // 高亮反显下拉项
    lightItem: function lightItem() {
      var _this2 = this;

      if (this.selectValue && this.selectValue != '') {
        this.checkedList.forEach(function (item) {
          if (item.secUuid == _this2.selectValue) {
            item.isSelected = true;
          } else {
            item.isSelected = false;
          }
        });
        this.initCheckedList.forEach(function (item) {
          if (item.secUuid == _this2.selectValue) {
            item.isSelected = true;
          } else {
            item.isSelected = false;
          }
        });
      }
    },
    playFunction: function playFunction(url, id) {
      this.$nextTick(function () {
        var afId = '#' + id;
        var player = new _svgaplayerweb.default.Player(afId);
        var parser = new _svgaplayerweb.default.Parser(afId);
        parser.load(url, function (videoItem) {
          player.setVideoItem(videoItem);
          player.startAnimation();
        });
      });
    }
  },
  created: function created() {
    // if(this.value) {
    //   let sdsd = this.value
    //   debugger
    // }
    this.selectValue = this.value;
    this.getModelData();
  }
};
exports.default = _default2;