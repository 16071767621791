"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.array.splice.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.number.constructor.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _CommonService = _interopRequireDefault(require("@/api/CommonService"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {},
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    limit: {
      type: Number,
      default: function _default() {
        return 1;
      }
    },
    isDetail: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    paramData: {
      type: Object,
      required: false
    }
  },
  data: function data() {
    return {
      file: []
    };
  },
  computed: {// convertImgUrlPrefix() {
    //   return (url) => {
    //     return convertImgUrlPrefix(url);
    //   };
    // },
    // 活动规则图片上传
  },
  watch: {
    value: function value(val, oldVal) {
      if (val != oldVal) {
        this.file = this.value;
      }
    }
  },
  methods: {
    handleUpload: function handleUpload(_ref) {
      var _this = this;

      var file = _ref.file;
      this.$emit('btnLoad', true);

      _CommonService.default.uploadSingleTemp(file).then(function (result) {
        _this.$emit('btnLoad', false);

        _this.file = [file];
        var fileInfo = [{}];
        fileInfo[0].name = result.data[0].name;
        fileInfo[0].uname = result.data[0].uname;
        fileInfo[0].url = result.data[0].url;
        fileInfo[0].secUuid = result.data[0].secUuid;

        _this.syncFileInfo(fileInfo);
      });
    },
    // 活动规则图片删除之前
    beforeRemove: function beforeRemove(file, fileList) {
      var _this2 = this;

      console.log(file);
      return this.$confirm("\u786E\u5B9A\u79FB\u9664 ".concat(file.name, "\uFF1F")).then(function () {
        var fileIndex = 0;
        fileList.map(function (item, index) {
          if (item.uid == file.uid) {
            fileIndex = index;
          }
        });
        var resultArr = JSON.parse(JSON.stringify(fileList));
        resultArr.splice(fileIndex, 1);

        _this2.$emit("change", resultArr);
      });
    },
    // 活动规则图片上传文件超出个数限制时
    handleExceed: function handleExceed(files, fileList) {
      this.$message.warning("\u53EA\u80FD\u4E0A\u4F20".concat(this.limit, "\u5F20\u56FE\u7247\u54E6\uFF01"));
    },

    /**
     * 同步fileInfo
     */
    syncFileInfo: function syncFileInfo(fileList) {
      var result = [];

      if (fileList.length > 0) {
        result = fileList;
      } else {
        result = [];
      } // this.$emit('input', result)


      if (this.paramData) {
        this.$emit('change', result, this.paramData);
      } else {
        this.$emit('change', result);
      }
    },

    /**
     * 删除图片
     */
    handleRemove: function handleRemove(file, fileList) {
      console.log(file, fileList);
      this.syncFileInfo(fileList);
    }
  },
  created: function created() {
    if (this.value.length) {
      this.file = this.value;
    }
  }
};
exports.default = _default2;