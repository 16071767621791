var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "daigSelfContainer",
      attrs: {
        title: _vm.row.id ? "编辑" : "新增",
        visible: _vm.visibleDialog,
        "close-on-click-modal": false,
        width: "900px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visibleDialog = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "rowForm",
          attrs: {
            model: _vm.row,
            "label-width": "150px",
            rules: _vm.rowFormRules
          }
        },
        [
          _c(
            "el-tabs",
            {
              staticClass: "tabControl",
              staticStyle: { height: "100%" },
              attrs: {
                "tab-position": "left",
                stretch: "",
                "before-leave": _vm.beforeTab
              },
              model: {
                value: _vm.tabsActiveName,
                callback: function($$v) {
                  _vm.tabsActiveName = $$v
                },
                expression: "tabsActiveName"
              }
            },
            [
              _vm.modeTypeCode == 1
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "页面", name: "page" } },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "提升礼底框", prop: "r1" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r1,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr1Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "活动细侧边栏", prop: "r2" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r2,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr2Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "活动内容", prop: "r3" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r3,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr3Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "我的奖品边栏", prop: "r4" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r4,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr4Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "参与步骤", prop: "r5" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r5,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr5Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "报名成功弹窗背景", prop: "r6" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r6,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr6Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "产品推荐底框", prop: "r7" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r7,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr7Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "确定按钮", prop: "r8" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r8,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr8Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "报名按钮", prop: "r9" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r9,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr9Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "关闭按钮", prop: "r10" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r10,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr10Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "查看进度按钮", prop: "r11" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r11,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr11Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "活动细则弹窗背景", prop: "r12" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r12,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr12Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "领奖成功弹窗背景", prop: "r13" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r13,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr13Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "我的奖品弹窗背景", prop: "r14" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r14,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr14Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "分享按钮", prop: "r15" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r15,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr15Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "等级按钮1", prop: "r16" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r16,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr16Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "活动进度底框", prop: "r17" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r17,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr17Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "等级按钮2", prop: "r18" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r18,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr18Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "提升指南底框", prop: "r19" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r19,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr19Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "等级按钮3", prop: "r20" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r20,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr20Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "等级置灰按钮1", prop: "r21" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r21,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr21Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "等级置灰按钮2", prop: "r22" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r22,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr22Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "等级置灰按钮3", prop: "r23" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r23,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr23Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条圆点", prop: "r24" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r24,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr24Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "等级展示底框", prop: "r25" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r25,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr25Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条背景等级1", prop: "r26" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r26,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr26Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条背景等级2", prop: "r27" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r27,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr27Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条背景等级3", prop: "r28" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r28,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr28Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条灰底等级1", prop: "r29" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r29,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr29Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条灰底等级2", prop: "r30" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r30,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr30Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条灰底等级3", prop: "r31" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r31,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr31Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条彩色等级1", prop: "r32" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r32,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr32Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条彩色等级2", prop: "r33" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r33,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr33Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条彩色等级3", prop: "r34" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r34,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr34Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "领奖置灰按钮", prop: "r35" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r35,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr35Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "领奖按钮", prop: "r36" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r36,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr36Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "返回按钮", prop: "r37" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r37,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr37Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm.modeTypeCode == 2
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "页面", name: "page" } },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "提升礼底框", prop: "r1" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r1,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr1Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "活动细侧边栏", prop: "r2" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r2,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr2Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "活动内容", prop: "r3" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r3,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr3Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "我的奖品边栏", prop: "r4" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r4,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr4Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "参与步骤", prop: "r5" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r5,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr5Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "报名成功弹窗背景", prop: "r6" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r6,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr6Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "产品推荐底框", prop: "r7" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r7,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr7Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "确定按钮", prop: "r8" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r8,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr8Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "报名按钮", prop: "r9" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r9,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr9Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "关闭按钮", prop: "r10" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r10,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr10Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "查看进度按钮", prop: "r11" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r11,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr11Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "活动细则弹窗背景", prop: "r12" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r12,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr12Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "领奖成功弹窗背景", prop: "r13" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r13,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr13Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "我的奖品弹窗背景", prop: "r14" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r14,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr14Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "分享按钮", prop: "r15" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r15,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr15Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "等级按钮1", prop: "r16" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r16,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr16Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "活动进度底框", prop: "r17" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r17,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr17Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "等级按钮2", prop: "r18" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r18,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr18Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "提升指南底框", prop: "r19" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r19,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr19Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "等级按钮3", prop: "r20" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r20,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr20Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "等级置灰按钮1", prop: "r21" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r21,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr21Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "等级置灰按钮2", prop: "r22" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r22,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr22Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "等级置灰按钮3", prop: "r23" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r23,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr23Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条圆点", prop: "r24" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r24,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr24Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "等级展示底框", prop: "r25" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r25,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr25Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条背景等级1", prop: "r26" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r26,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr26Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条背景等级2", prop: "r27" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r27,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr27Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条背景等级3", prop: "r28" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r28,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr28Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条灰底等级1", prop: "r29" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r29,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr29Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条灰底等级2", prop: "r30" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r30,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr30Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条灰底等级3", prop: "r31" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r31,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr31Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条彩色等级1", prop: "r32" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r32,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr32Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条彩色等级2", prop: "r33" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r33,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr33Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条彩色等级3", prop: "r34" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r34,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr34Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "领奖置灰按钮", prop: "r35" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r35,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr35Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "领奖按钮", prop: "r36" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r36,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr36Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "返回按钮", prop: "r37" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r37,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr37Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "已达标提升礼背景", prop: "r38" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r38,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr38Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "已达标背景", prop: "r39" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r39,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr39Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "未达标背景", prop: "r40" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r40,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr40Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "未达标提升礼背景", prop: "r41" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r41,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr41Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm.modeTypeCode == 3
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "页面", name: "page" } },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "活动背景", prop: "r1" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r1,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr1Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "资产达标背景", prop: "r2" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r2,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr2Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "立减金1", prop: "r3" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r3,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr3Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "立减金2", prop: "r4" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r4,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr4Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "立减金3", prop: "r5" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r5,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr5Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "温馨提示框", prop: "r6" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r6,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr6Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "立即报名按钮", prop: "r7" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r7,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr7Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "存款推荐背景", prop: "r8" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r8,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr8Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "报名成功弹窗", prop: "r9" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r9,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr9Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "好友助力背景", prop: "r10" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r10,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr10Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "邀请好友完成光标", prop: "r11" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r11,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr11Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "邀请好友光标", prop: "r12" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r12,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr12Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: {
                                label: "好友助力进度条底框",
                                prop: "r13"
                              }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r13,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr13Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "邀请好友按钮", prop: "r14" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r14,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr14Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "好友助力历史背景", prop: "r15" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r15,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr15Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "直接领取按钮", prop: "r16" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r16,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr16Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "分享助力按钮", prop: "r17" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r17,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr17Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "分享弹窗", prop: "r18" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r18,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr18Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "为好友助力按钮", prop: "r19" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r19,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr19Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "已完成助力按钮", prop: "r20" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r20,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr20Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "助力提示弹窗", prop: "r21" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r21,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr21Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "注册按钮", prop: "r22" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r22,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr22Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "注册取消按钮", prop: "r23" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r23,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr23Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "报名失败弹窗", prop: "r24" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r24,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr24Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "活动进度背景", prop: "r25" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r25,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr25Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度内容小框", prop: "r26" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r26,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr26Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "去领奖置灰按钮", prop: "r27" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r27,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr27Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "已抢完按钮", prop: "r28" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r28,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr28Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "助力中图标", prop: "r29" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r29,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr29Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "助力领奖中按钮", prop: "r30" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r30,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr30Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "已领奖按钮", prop: "r31" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r31,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr31Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "待领奖按钮", prop: "r32" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r32,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr32Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "我的助力失败按钮", prop: "r33" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r33,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr33Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "我的助力过期背景", prop: "r34" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r34,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr34Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条底框", prop: "r35" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r35,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr35Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条彩色", prop: "r36" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r36,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr36Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "我的助力成功背景", prop: "r37" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r37,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr37Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "点击膨胀按钮", prop: "r38" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r38,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr38Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "领奖成功弹窗", prop: "r39" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r39,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr39Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "弹窗关闭按钮", prop: "r40" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r40,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr40Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "活动细则边栏", prop: "r41" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r41,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr41Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "我的奖品边栏", prop: "r42" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r42,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr42Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "我的奖品弹窗", prop: "r43" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r43,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr43Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "确定按钮", prop: "r44" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r44,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr44Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "活动细则弹窗", prop: "r45" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r45,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr45Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "查看进度按钮", prop: "r46" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r46,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr46Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "报名成功按钮", prop: "r47" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r47,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr47Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm.modeTypeCode == 4
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "页面", name: "page" } },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "活动背景", prop: "r1" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r1,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr1Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "活动进度背景", prop: "r2" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r2,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr2Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "消费3天条框", prop: "r3" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r3,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr3Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "消费10天条框", prop: "r4" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r4,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr4Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "消费达标送红包背景", prop: "r5" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r5,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr5Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "立即报名按钮", prop: "r6" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r6,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr6Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "活动细则边栏", prop: "r7" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r7,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr7Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "我的奖品边栏", prop: "r8" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r8,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr8Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "领取按钮", prop: "r9" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r9,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr9Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "已领取按钮", prop: "r10" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r10,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr10Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "我的奖品弹窗", prop: "r11" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r11,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr11Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "确定按钮", prop: "r12" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r12,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr12Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "关闭按钮", prop: "r13" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r13,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr13Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "活动细则弹窗", prop: "r14" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r14,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr14Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "报名成功弹窗", prop: "r15" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r15,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr15Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "报名失败弹窗", prop: "r16" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r16,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr16Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "报名弹窗确定按钮", prop: "r17" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r17,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr17Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "报名弹窗返回按钮", prop: "r18" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r18,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr18Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "领奖成功弹窗", prop: "r19" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r19,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr19Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "分享朋友圈按钮", prop: "r20" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r20,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr20Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm.modeTypeCode == 7
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "页面", name: "page" } },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "首次动效", prop: "r1" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r1,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail,
                                  index: 1
                                },
                                on: { selected: _vm.setSelectr1Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "活动动效背景", prop: "r2" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r2,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail,
                                  index: 2
                                },
                                on: { selected: _vm.setSelectr2Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm.modeTypeCode == 15
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "页面", name: "page" } },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "领奖助力弹窗", prop: "r1" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r1,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr1Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "弹窗关闭图标", prop: "r2" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r2,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr2Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "分享助力按钮", prop: "r3" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r3,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr3Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "直接领取按钮", prop: "r4" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r4,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr4Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "助力悬浮图片", prop: "r5" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r5,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr5Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "邀请好友助力领券", prop: "r6" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r6,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr6Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "助力倒计时背景框", prop: "r7" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r7,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr7Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "助力进度条灰底", prop: "r8" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r8,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr8Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "助力进度条彩色进度", prop: "r9" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r9,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr9Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "助力进度图标", prop: "r10" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r10,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr10Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "助力进度完成图标", prop: "r11" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r11,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr11Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "为好友助力按钮", prop: "r12" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r12,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr12Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "点击膨胀按钮", prop: "r13" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r13,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr13Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "助力失败按钮", prop: "r14" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r14,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr14Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: {
                                label: "好友助力历史背景框",
                                prop: "r15"
                              }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r15,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr15Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "活动细则弹窗背景", prop: "r16" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r16,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr16Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "助力提示弹窗", prop: "r17" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r17,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr17Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "注册按钮", prop: "r18" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r18,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr18Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "注册取消按钮", prop: "r19" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r19,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr19Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "已完成助力按钮", prop: "r20" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r20,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr20Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "我的助力失败按钮", prop: "r21" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r21,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr21Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "我的助力过期背景", prop: "r21" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r21,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr21Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "我的助力成功背景", prop: "r22" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r22,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr22Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "点击膨胀按钮", prop: "r23" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r23,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr23Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _c(
                    "el-tab-pane",
                    { attrs: { label: "页面", name: "page" } },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "提升礼底框", prop: "r1" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r1,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr1Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "活动细侧边栏", prop: "r2" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r2,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr2Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "活动内容", prop: "r3" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r3,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr3Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "我的奖品边栏", prop: "r4" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r4,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr4Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "参与步骤", prop: "r5" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r5,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr5Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "报名成功弹窗背景", prop: "r6" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r6,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr6Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "产品推荐底框", prop: "r7" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r7,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr7Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "确定按钮", prop: "r8" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r8,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr8Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "报名按钮", prop: "r9" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r9,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr9Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "关闭按钮", prop: "r10" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r10,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr10Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "查看进度按钮", prop: "r11" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r11,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr11Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "活动细则弹窗背景", prop: "r12" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r12,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr12Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "领奖成功弹窗背景", prop: "r13" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r13,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr13Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "我的奖品弹窗背景", prop: "r14" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r14,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr14Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "分享按钮", prop: "r15" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r15,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr15Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "等级按钮1", prop: "r16" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r16,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr16Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "活动进度底框", prop: "r17" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r17,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr17Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "等级按钮2", prop: "r18" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r18,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr18Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "提升指南底框", prop: "r19" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r19,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr19Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "等级按钮3", prop: "r20" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r20,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr20Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "等级置灰按钮1", prop: "r21" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r21,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr21Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "等级置灰按钮2", prop: "r22" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r22,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr22Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "等级置灰按钮3", prop: "r23" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r23,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr23Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条圆点", prop: "r24" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r24,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr24Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "等级展示底框", prop: "r25" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r25,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr25Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条背景等级1", prop: "r26" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r26,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr26Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条背景等级2", prop: "r27" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r27,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr27Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条背景等级3", prop: "r28" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r28,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr28Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条灰底等级1", prop: "r29" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r29,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr29Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条灰底等级2", prop: "r30" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r30,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr30Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条灰底等级3", prop: "r31" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r31,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr31Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条彩色等级1", prop: "r32" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r32,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr32Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条彩色等级2", prop: "r33" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r33,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr33Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "进度条彩色等级3", prop: "r34" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r34,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr34Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "领奖置灰按钮", prop: "r35" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r35,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr35Value }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "领奖按钮", prop: "r36" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r36,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr36Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { label: "返回按钮", prop: "r37" }
                            },
                            [
                              _c("selectComponent", {
                                attrs: {
                                  value: _vm.row.r37,
                                  list: _vm.selectPictureList,
                                  isDetail: _vm.isDetail
                                },
                                on: { selected: _vm.setSelectr37Value }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
              _vm.modeTypeCode == 1
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "文本效果", name: "text" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "报名成功等级提升", prop: "r51" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r51,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr51Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "报名成功等级", prop: "r52" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r52,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr52Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "提升金额信息", prop: "r53" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r53,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr53Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "提升金额", prop: "r54" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r54,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr54Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "温馨提示", prop: "r55" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r55,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr55Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "进度条提示", prop: "r56" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r56,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr56Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "领奖成功弹窗奖品名称", prop: "r57" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r57,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr57Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "领奖成功弹窗提示信息", prop: "r58" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r58,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr58Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "我的奖品弹窗无记录", prop: "r59" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r59,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr59Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "我的奖品弹窗奖品名称", prop: "r60" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r60,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr60Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "我的奖品弹窗奖品信息", prop: "r61" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r61,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr61Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "等级提升指南信息", prop: "r62" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r62,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr62Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "等级提升指南金额信息", prop: "r63" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r63,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr63Value }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm.modeTypeCode == 2
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "文本效果", name: "text" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "报名成功等级提升", prop: "r51" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r51,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr51Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "报名成功等级", prop: "r52" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r52,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr52Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "提升金额信息", prop: "r53" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r53,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr53Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "提升金额", prop: "r54" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r54,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr54Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "温馨提示", prop: "r55" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r55,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr55Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "进度条提示", prop: "r56" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r56,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr56Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "领奖成功弹窗奖品名称", prop: "r57" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r57,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr57Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "领奖成功弹窗提示信息", prop: "r58" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r58,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr58Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "我的奖品弹窗无记录", prop: "r59" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r59,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr59Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "我的奖品弹窗奖品名称", prop: "r60" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r60,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr60Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "我的奖品弹窗奖品信息", prop: "r61" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r61,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr61Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "等级提升指南信息", prop: "r62" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r62,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr62Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "等级提升指南金额信息", prop: "r63" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r63,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr63Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "本月资产信息", prop: "r64" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r64,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr64Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "本月资产金额", prop: "r65" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r65,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr65Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "尚未达标提示", prop: "r66" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r66,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr66Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "未达标内容信息", prop: "r67" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r67,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr67Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "未达标内容金额", prop: "r68" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r68,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr68Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "上月资产信息", prop: "r69" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r69,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr69Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "上月资产金额", prop: "r70" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r70,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr70Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "已达标提示", prop: "r71" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r71,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr71Value }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm.modeTypeCode == 3
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "文本效果", name: "text" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "邀请好友标题", prop: "r51" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r51,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr51Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "邀请好友助力内容", prop: "r52" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r52,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr52Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "上月资产内容", prop: "r53" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r53,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr53Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "上月资产金额", prop: "r54" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r54,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr54Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "上月达标提示", prop: "r55" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r55,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr55Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "本月资产内容", prop: "r56" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r56,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr56Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "本月资产金额", prop: "r57" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r57,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr57Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "本月达标提示", prop: "r58" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r58,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr58Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "助力内容", prop: "r59" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r59,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr59Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "助力历史表头", prop: "r60" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r60,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr60Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "助力历史内容", prop: "r61" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r61,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr61Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "我的奖品弹窗无记录", prop: "r62" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r62,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr62Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "我的奖品弹窗奖品名称", prop: "r63" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r63,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr63Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "我的奖品弹窗奖品信息", prop: "r64" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r64,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr64Value }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm.modeTypeCode == 4
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "文本效果", name: "text" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "活动进度状态信息", prop: "r51" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r51,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr51Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "活动进度状态天数", prop: "r52" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r52,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr52Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "消费达标送红包标签", prop: "r53" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r53,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr53Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "消费达标送红包2条内容", prop: "r54" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r54,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr54Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "消费达标送红包3条内容", prop: "r55" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r55,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr55Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "领奖成功弹窗奖品名称", prop: "r56" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r56,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr56Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "领奖成功弹窗提示信息", prop: "r57" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r57,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr57Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "我的奖品弹窗无记录", prop: "r58" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r58,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr58Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "我的奖品弹窗奖品名称", prop: "r59" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r59,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr59Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "我的奖品弹窗奖品信息", prop: "r60" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r60,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr60Value }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm.modeTypeCode == 7
                ? _c("el-tab-pane", {
                    attrs: { label: "文本效果", name: "text" }
                  })
                : _vm.modeTypeCode == 15
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "文本效果", name: "text" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "助力倒计时标题", prop: "r51" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r51,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr51Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "好友助力进度提示", prop: "r52" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r52,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr52Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "好友助力进度提示数目", prop: "r53" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r53,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr53Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "助力历史表头", prop: "r54" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r54,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr54Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "助力历史内容", prop: "r55" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r55,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr55Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "进度条进度百分比", prop: "r56" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r56,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr56Value }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "el-tab-pane",
                    { attrs: { label: "文本效果", name: "text" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "报名成功等级提升", prop: "r51" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r51,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr51Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "报名成功等级", prop: "r52" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r52,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr52Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "提升金额信息", prop: "r53" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r53,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr53Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "提升金额", prop: "r54" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r54,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr54Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "温馨提示", prop: "r55" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r55,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr55Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "进度条提示", prop: "r56" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r56,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr56Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "领奖成功弹窗奖品名称", prop: "r57" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r57,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr57Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "领奖成功弹窗提示信息", prop: "r58" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r58,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr58Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "我的奖品弹窗无记录", prop: "r59" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r59,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr59Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "我的奖品弹窗奖品名称", prop: "r60" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r60,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr60Value }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { flex: "1" },
                          attrs: { label: "我的奖品弹窗奖品信息", prop: "r61" }
                        },
                        [
                          _c("selectComponent", {
                            attrs: {
                              value: _vm.row.r61,
                              list: _vm.selectTextList,
                              isDetail: _vm.isDetail
                            },
                            on: { selected: _vm.setSelectr61Value }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                width: "700px",
                title: "保存",
                visible: _vm.saveDialogVisible,
                "append-to-body": ""
              },
              on: {
                "update:visible": function($event) {
                  _vm.saveDialogVisible = $event
                }
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "素材配置项名称", prop: "configName" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "200" },
                    model: {
                      value: _vm.row.configName,
                      callback: function($$v) {
                        _vm.$set(_vm.row, "configName", $$v)
                      },
                      expression: "row.configName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "描述", prop: "comment" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "请输入描述" },
                    model: {
                      value: _vm.row.comment,
                      callback: function($$v) {
                        _vm.$set(_vm.row, "comment", $$v)
                      },
                      expression: "row.comment"
                    }
                  })
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c("el-button", { on: { click: _vm.cancelSaveDialog } }, [
                    _vm._v("取 消")
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSave }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.isDetail
        ? _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("关闭")
              ])
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取消")
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnSaveLoading },
                  on: { click: _vm.handleSaveBefore }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }