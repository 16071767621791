"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.object.to-string.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _FinManagerService = _interopRequireDefault(require("@/api/FinManagerService"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      selectedRow: {},
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      listLoading: true,
      options: [{
        value: '1',
        label: '指南',
        children: [{
          value: '2',
          label: '设计原则'
        }]
      }, {
        value: '3',
        label: '指南2',
        children: [{
          value: '4',
          label: '设计原则1'
        }, {
          value: '5',
          label: '设计原则2'
        }]
      }],
      visibleDrawer: false
    };
  },
  computed: {},
  created: function created() {
    this.getTopDeparment();
  },
  methods: {
    handleClose: function handleClose() {
      this.visibleDrawer = false;
    },
    loadNode: function loadNode(node, resolve) {
      var _this = this;

      console.log(node);

      if (node.level === 0) {
        return resolve([{
          name: 'region'
        }]);
      }

      setTimeout(function () {
        _FinManagerService.default.getDeparment(node.data.value).then(function (result) {
          var arrData = result.data.map(function (item) {
            item.value = item.ORGNID;
            item.label = item.ORGNNAME;
            item.isLeaf = true;
            item.parentCode = node.data.ORGNCODE;
            item.parentName = node.data.ORGNNAME;
            return item;
          });
          result.hasFailure(function () {
            return _this.$message.error(result.message);
          });
          resolve(arrData);
        });
      }, 500);
    },
    handleNodeClick: function handleNodeClick(data) {
      console.log(data);
      this.visibleDrawer = true;
      this.selectedRow = data;
    },
    getTopDeparment: function getTopDeparment() {
      var _this2 = this;

      return new Promise(function (resolve, reject) {
        _this2.listLoading = true;

        _FinManagerService.default.getTopDeparment().then(function (result) {
          _this2.listLoading = false;
          _this2.options = result.data.map(function (item) {
            item.value = item.ORGNID;
            item.label = item.ORGNNAME;
            item.isLeaf = true;
            return item;
          });
          result.hasFailure(function () {
            return _this2.$message.error(result.message);
          });
          resolve(_this2.options);
        });
      });
    }
  }
};
exports.default = _default;