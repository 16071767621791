var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dropDownBox" },
    [
      _vm.isDetail
        ? _c("div", [
            _vm.selectUrlRow.childType === 1
              ? _c("span", { staticClass: "dropDownDetailTitle" }, [
                  _vm.selectUrlRow.parameter
                    ? _c("span", [_vm._v(_vm._s(_vm.selectUrlRow.parameter))])
                    : _vm._e()
                ])
              : _c("div", { staticClass: "dropDownDetailTitle" }, [
                  _vm.selectUrlRow.videoUrl && _vm.selectUrlRow.videoUrl.url
                    ? _c("div", [
                        _vm.selectUrlRow.videoUrl.url.includes("svg")
                          ? _c("div", {
                              staticStyle: { width: "80px", height: "80px" },
                              attrs: { id: "svgaId" }
                            })
                          : _c(
                              "div",
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "80px",
                                    height: "80px"
                                  },
                                  attrs: {
                                    src: _vm.selectUrlRow.videoUrl.url,
                                    fit: "scale-down"
                                  }
                                })
                              ],
                              1
                            )
                      ])
                    : _vm._e()
                ])
          ])
        : _c(
            "el-dropdown",
            {
              attrs: { trigger: "click", placement: "bottom-start" },
              on: {
                command: _vm.handleCommand,
                "visible-change": _vm.visibleChange
              }
            },
            [
              _c("span", { staticClass: "el-dropdown-link dropDownTitle" }, [
                _vm.selectValue && _vm.selectValue != ""
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.selectPreview(_vm.selectValue)))
                    ])
                  : _c("span", { staticClass: "selectPlaceholder" }, [
                      _vm._v("请选择")
                    ]),
                _vm.drownShow
                  ? _c("i", { staticClass: "el-icon-arrow-up arrowIcon" })
                  : _c("i", { staticClass: "el-icon-arrow-down arrowIcon" })
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "dropDownContent",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "searchInput",
                          attrs: { placeholder: "搜索" },
                          on: { input: _vm.searchNameChange },
                          model: {
                            value: _vm.searchName,
                            callback: function($$v) {
                              _vm.searchName = $$v
                            },
                            expression: "searchName"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "el-input__icon el-icon-search",
                            attrs: { slot: "prefix" },
                            slot: "prefix"
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "itemBox" },
                    _vm._l(_vm.checkedList, function(item) {
                      return _c(
                        "el-dropdown-item",
                        {
                          key: item.id,
                          class: { itemActive: item.isSelected },
                          attrs: { command: item }
                        },
                        [
                          _c("div", { staticClass: "dropItemLine" }, [
                            _c("div", { staticClass: "dropItemLeft" }, [
                              _vm._v(_vm._s(item.label))
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "dropItemRight",
                                staticStyle: { width: "80px", height: "80px" }
                              },
                              [
                                item.videoUrl && item.videoUrl.url
                                  ? _c("div", [
                                      item.videoUrl.url.includes("svg")
                                        ? _c("div", [
                                            _c("div", {
                                              staticStyle: {
                                                width: "80px",
                                                height: "80px"
                                              },
                                              attrs: { id: item.svgaId }
                                            })
                                          ])
                                        : _c(
                                            "div",
                                            [
                                              item.videoUrl && item.videoUrl.url
                                                ? _c("el-image", {
                                                    staticStyle: {
                                                      width: "80px",
                                                      height: "80px"
                                                    },
                                                    attrs: {
                                                      src: item.videoUrl.url,
                                                      fit: "scale-down"
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }