"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.set.js");

require("core-js/modules/es.string.iterator.js");

require("core-js/modules/web.dom-collections.iterator.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _SystemRole = _interopRequireDefault(require("@/api/entity/SystemRole"));

var _SystemRoleService = _interopRequireDefault(require("@/api/SystemRoleService"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import AuthorityService from '@/api/AuthorityService' //权限树
var _default = {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: _SystemRole.default,
      required: true
    }
  },
  data: function data() {
    return {
      btnSaveLoading: false,
      tree: null,
      props: {
        label: 'name',
        children: 'nodes'
      },
      selectedCodes: new Set(),
      rules: {
        roleName: [{
          required: true,
          message: '请输入角色名称',
          trigger: 'blur'
        }, {
          min: 1,
          max: 100,
          message: '长度必须在 1-100 个字符',
          trigger: 'blur'
        }],
        // roleLevel: [
        //   {required: true, message: '请输入角色等级', trigger: 'blur'},
        //   {min: 1, max: 100, message: '长度必须在 1-100 个字符', trigger: 'blur'}
        // ],
        dataPowerType: [{
          required: true,
          message: '请选择数据权限',
          trigger: 'change'
        }],
        status: [{
          required: true,
          message: '请选择角色状态',
          trigger: 'change'
        }]
      },
      qxOptions: [{
        name: '本人',
        value: 'SELF'
      }, {
        name: '本分舵',
        value: 'RUDDER'
      }, {
        name: '全部',
        value: 'ALL'
      }]
    };
  },
  computed: {
    visibleDrawer: {
      get: function get() {
        return this.visible;
      },
      set: function set(val) {
        this.$emit('update:visible', val);
      }
    }
  },
  created: function created() {// AuthorityService.getTree().then(result => {
    //   result
    //     .hasFailure(() => this.$message.warning(result.getErrorMessage('权限树加载失败')))
    //     .hasData(data => {
    //       this.tree = data
    //     })
    // })
  },
  methods: {
    handleOpen: function handleOpen() {// if (this.row.authorities) { // 编辑时填充权限树
      //   const refTree = this.$refs.tree
      //   // 过滤:只选择叶子节点, 父节点会自动选中
      //   const keys = this.row.authorities.filter(code => refTree.getNode(code)?.isLeaf)
      //   refTree.setCheckedKeys(keys)
      // }
    },
    // handleChange(row, currentChecked, childChecked) {
    //   row.checked = currentChecked || childChecked
    //   if (!row.nodes) {
    //     if (currentChecked) {
    //       this.selectedCodes.add(row.code)
    //     } else {
    //       this.selectedCodes.delete(row.code)
    //     }
    //   }
    // },
    handleClose: function handleClose() {
      this.$refs.rowForm.resetFields(); // this.$refs.tree.setCheckedKeys([])

      this.visibleDrawer = false;
    },
    handleSave: function handleSave() {
      var _this = this;

      this.$refs.rowForm.validate(function (valid) {
        if (valid) {
          var role = new _SystemRole.default(_this.row);
          role.roleLevel = "1";
          _this.btnSaveLoading = true;
          (role.id ? _SystemRoleService.default.update(role) : _SystemRoleService.default.save(role)).then(function (result) {
            _this.btnSaveLoading = false;
            result.hasFailure(function () {
              return _this.$message.warning(result.getErrorMessage('保存失败'));
            }).hasSuccess(function () {
              _this.$message.success('保存成功');

              _this.handleClose();

              _this.$emit('ok');
            });
          });
        }
      });
    }
  }
};
exports.default = _default;