var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.flag,
          expression: "flag"
        }
      ],
      staticClass: "app-container",
      attrs: {
        "element-loading-text": "报表下载中，请稍等",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(0, 0, 0, 0.8)"
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "queryParam",
              attrs: { inline: true, model: _vm.queryParam }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择活动" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择活动" },
                      on: { change: _vm.activityChange },
                      model: {
                        value: _vm.queryParam.activityCode,
                        callback: function($$v) {
                          _vm.$set(_vm.queryParam, "activityCode", $$v)
                        },
                        expression: "queryParam.activityCode"
                      }
                    },
                    _vm._l(_vm.activityOptions, function(item) {
                      return _c("el-option", {
                        key: item.activityCode,
                        attrs: {
                          label: item.activityName,
                          value: item.activityCode
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "选择报表" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择报表" },
                      on: { change: _vm.reportChange },
                      model: {
                        value: _vm.queryParam.reportType,
                        callback: function($$v) {
                          _vm.$set(_vm.queryParam, "reportType", $$v)
                        },
                        expression: "queryParam.reportType"
                      }
                    },
                    _vm._l(_vm.statementTypeOptions, function(item) {
                      return _c("el-option", {
                        key: item.reportType,
                        attrs: {
                          label: item.reportName,
                          value: item.reportType
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "monthrange",
                      align: "right",
                      "unlink-panels": "",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "yyyy-MM",
                      clearable: true,
                      "clear-icon": "el-icon-error",
                      disabled: _vm.datePickerCtrl
                    },
                    on: {
                      change: function($event) {
                        return _vm.dateChange($event)
                      }
                    },
                    model: {
                      value: _vm.dateValue,
                      callback: function($$v) {
                        _vm.dateValue = $$v
                      },
                      expression: "dateValue"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.btnCtrl },
                      on: { click: _vm.handleQuery }
                    },
                    [_vm._v("查询 ")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.download } },
                    [_vm._v(" 导 出 ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.activityRow.activityType == 35 && _vm.queryParam.reportType == "02"
        ? _c(
            "div",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ],
                  attrs: {
                    data: _vm.list,
                    "cell-style": { "text-align": "center" },
                    "header-cell-style": { "text-align": "center" },
                    "element-loading-text": "Loading",
                    border: "",
                    fit: "",
                    "highlight-current-row": ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "客户号", prop: "customerId" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "首次浏览时间", prop: "firstBrowseTime" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "首次动效选择红包类型",
                      prop: "firstDynamicEffectsRedEnvelopeChoiceState"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "选择时间",
                      prop: "firstDynamicEffectsRedEnvelopeChoiceTime"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "活动浏览天数", prop: "browseDays" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "活动浏览次数", prop: "browseCount" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "最近浏览时间", prop: "latestBrowseTime" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "渠道号", prop: "channelNo" }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pagination-container" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.queryParam.pageNo,
                      "page-size": _vm.queryParam.pageSize,
                      total: _vm.queryParam.totalCount,
                      "page-sizes": [100, 50, 200, 500, 1000],
                      layout: "total, sizes, prev, pager, next, jumper"
                    },
                    on: {
                      "update:currentPage": function($event) {
                        return _vm.$set(_vm.queryParam, "pageNo", $event)
                      },
                      "update:current-page": function($event) {
                        return _vm.$set(_vm.queryParam, "pageNo", $event)
                      },
                      "update:pageSize": function($event) {
                        return _vm.$set(_vm.queryParam, "pageSize", $event)
                      },
                      "update:page-size": function($event) {
                        return _vm.$set(_vm.queryParam, "pageSize", $event)
                      },
                      "update:total": function($event) {
                        return _vm.$set(_vm.queryParam, "totalCount", $event)
                      },
                      "size-change": _vm.handleQuery,
                      "current-change": _vm.handleQuery
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.activityRow.activityType == 35 && _vm.queryParam.reportType == "01"
        ? _c(
            "div",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ],
                  attrs: {
                    data: _vm.list,
                    "cell-style": { "text-align": "center" },
                    "header-cell-style": { "text-align": "center" },
                    "element-loading-text": "Loading",
                    border: "",
                    fit: "",
                    "highlight-current-row": ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "日期", prop: "statisticsTime" }
                  }),
                  _c("el-table-column", { attrs: { label: "UV", prop: "uv" } }),
                  _c("el-table-column", {
                    attrs: {
                      label: "首次动效触发人数",
                      prop: "firstDynamicEffectsCustomerCount"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "首次动效选择红包类型的人数",
                      prop: "firstDynamicEffectsRedEnvelopeChoiceCustomerCount"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "首次签到人数",
                      prop: "firstSigninCustomerCount"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "首次未签到人数",
                      prop: "firstNotSigninCustomerCount"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "流失率", prop: "churnRate" }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pagination-container" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.queryParam.pageNo,
                      "page-size": _vm.queryParam.pageSize,
                      total: _vm.queryParam.totalCount,
                      "page-sizes": [50, 100, 200, 500, 1000],
                      layout: "total, sizes, prev, pager, next, jumper"
                    },
                    on: {
                      "update:currentPage": function($event) {
                        return _vm.$set(_vm.queryParam, "pageNo", $event)
                      },
                      "update:current-page": function($event) {
                        return _vm.$set(_vm.queryParam, "pageNo", $event)
                      },
                      "update:pageSize": function($event) {
                        return _vm.$set(_vm.queryParam, "pageSize", $event)
                      },
                      "update:page-size": function($event) {
                        return _vm.$set(_vm.queryParam, "pageSize", $event)
                      },
                      "update:total": function($event) {
                        return _vm.$set(_vm.queryParam, "totalCount", $event)
                      },
                      "size-change": _vm.handleQuery,
                      "current-change": _vm.handleQuery
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.activityRow.activityType == 26 && _vm.queryParam.reportType == "03"
        ? _c(
            "div",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ],
                  attrs: {
                    data: _vm.list,
                    "cell-style": { "text-align": "center" },
                    "header-cell-style": { "text-align": "center" },
                    "element-loading-text": "Loading",
                    border: "",
                    fit: "",
                    "highlight-current-row": ""
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "客户号", prop: "bankCusId" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "首次浏览时间", prop: "firstBrowseTime" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "活动浏览天数", prop: "browseDays" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "活动浏览次数", prop: "browseCount" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "最近浏览时间", prop: "lastBrowseTime" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "渠道号", prop: "channelCode" }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pagination-container" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.queryParam.pageNo,
                      "page-size": _vm.queryParam.pageSize,
                      total: _vm.queryParam.totalCount,
                      "page-sizes": [50, 100, 200, 500, 1000],
                      layout: "total, sizes, prev, pager, next, jumper"
                    },
                    on: {
                      "update:currentPage": function($event) {
                        return _vm.$set(_vm.queryParam, "pageNo", $event)
                      },
                      "update:current-page": function($event) {
                        return _vm.$set(_vm.queryParam, "pageNo", $event)
                      },
                      "update:pageSize": function($event) {
                        return _vm.$set(_vm.queryParam, "pageSize", $event)
                      },
                      "update:page-size": function($event) {
                        return _vm.$set(_vm.queryParam, "pageSize", $event)
                      },
                      "update:total": function($event) {
                        return _vm.$set(_vm.queryParam, "totalCount", $event)
                      },
                      "size-change": _vm.handleQuery,
                      "current-change": _vm.handleQuery
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }