"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.number.constructor.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.includes.js");

require("core-js/modules/es.string.split.js");

require("core-js/modules/web.dom-collections.for-each.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _MaterialConfigService = _interopRequireDefault(require("@/api/MaterialConfigService"));

var _SelectComponent = _interopRequireDefault(require("@/views/materialManagement/materialConfig/components/SelectComponent"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    selectComponent: _SelectComponent.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      required: true
    },
    modeTypeCode: {
      type: Number,
      required: false
    },
    isDetail: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    }
  },
  data: function data() {
    return {
      btnSaveLoading: false,
      roles: [],
      rowFormRules: {// taskTitle: [
        //   { required: true, message: "请输入任务名称", trigger: "blur" },
        // ],
        // taskCode: [
        //   { required: true, message: "请输入任务编码", trigger: "blur" },
        // ],
        // taskType: [
        //   { required: true, message: "请选择任务类型", trigger: "change" },
        // ],
        // executeClass: [
        //   { required: true, message: "请输入执行类全名", trigger: "blur" },
        // ],
        // status: [{ required: true, message: "请选择状态", trigger: "change" }],
      },
      saveRules: {// taskTitle: [
        //   { required: true, message: "请输入任务名称", trigger: "blur" },
        // ],
        // taskCode: [
        //   { required: true, message: "请输入任务编码", trigger: "blur" },
        // ],
        // taskType: [
        //   { required: true, message: "请选择任务类型", trigger: "change" },
        // ],
        // executeClass: [
        //   { required: true, message: "请输入执行类全名", trigger: "blur" },
        // ],
        // status: [{ required: true, message: "请选择状态", trigger: "change" }],
      },
      // 模板类型
      modelTypeOptions: [],
      //   statusArr: [
      //     { value: "ENABLE", title: "启用" },
      //     { value: "DISABLE", title: "停用" },
      //   ],
      // disCtrl: true,
      // uploadFile: [],
      tabsActiveName: "page",
      selectPicType: 0,
      selectTextType: 1,
      saveRow: {},
      saveDialogVisible: false,
      editRow: {},
      selectPictureList: [],
      selectTextList: [],
      defaultList: []
    };
  },
  computed: {
    visibleDialog: {
      get: function get() {
        return this.visible;
      },
      set: function set(val) {
        this.$emit("update:visible", val);
      }
    }
  },
  watch: {},
  methods: {
    handleCancel: function handleCancel() {
      // this.$refs.rowForm.resetFields();
      this.visibleDialog = false;
    },
    cancelSaveDialog: function cancelSaveDialog() {
      // this.$refs.rowForm.resetFields();
      this.saveDialogVisible = false;
    },
    handleSaveBefore: function handleSaveBefore() {
      this.saveDialogVisible = true;
    },
    handleSave: function handleSave() {
      var _this = this;

      this.$refs.rowForm.validate(function (valid) {
        if (valid) {
          var detailArr = []; // 图片

          for (var i = 1; i <= 47; i++) {
            var itemObj = {
              fieldName: 'ITEM_' + i,
              fileUuid: _this.row['r' + i] && _this.row['r' + i] != '' ? _this.row['r' + i] : null
            };

            if (itemObj.fileUuid) {
              detailArr.push(itemObj);
            }
          } // 文本


          for (var _i = 51; _i <= 71; _i++) {
            var _itemObj = {
              fieldName: 'ITEM_' + _i,
              fileUuid: _this.row['r' + _i] && _this.row['r' + _i] != '' ? _this.row['r' + _i] : null
            };

            if (_itemObj.fileUuid) {
              detailArr.push(_itemObj);
            }
          }

          var postParam = {
            configName: _this.row.configName,
            comment: _this.row.comment,
            type: _this.row.id ? _this.row.type : _this.modeTypeCode,
            details: detailArr
          };

          if (_this.row.id) {
            postParam.id = _this.row.id;
          }

          _this.btnSaveLoading = true;
          (_this.row.id ? _MaterialConfigService.default.update(postParam) : _MaterialConfigService.default.save(postParam)).then(function (result) {
            result.hasFailure(function () {
              _this.btnSaveLoading = false;

              _this.$message.warning(result.getErrorMessage("保存失败"));
            }).hasSuccess(function () {
              _this.btnSaveLoading = false;

              _this.$message.success("保存成功");

              _this.handleCancel();

              _this.$emit("ok");
            });
          });
        } else {
          return false;
        }
      });
    },
    // 切换tab
    beforeTab: function beforeTab(activeName, oldActiveName) {},
    getModelData: function getModelData() {
      var _this2 = this;

      _MaterialConfigService.default.getModelList().then(function (result) {
        _this2.modelTypeOptions = [];
        result.data.forEach(function (item) {
          var obj = {
            dictValue: item.id,
            dictLabel: item.text
          };

          _this2.modelTypeOptions.push(obj);
        });
      });
    },
    // 选项框选中回调
    setSelectr1Value: function setSelectr1Value(val) {
      this.row.r1 = val;
    },
    setSelectr2Value: function setSelectr2Value(val) {
      this.row.r2 = val;
    },
    setSelectr3Value: function setSelectr3Value(val) {
      this.row.r3 = val;
    },
    setSelectr4Value: function setSelectr4Value(val) {
      this.row.r4 = val;
    },
    setSelectr5Value: function setSelectr5Value(val) {
      this.row.r5 = val;
    },
    setSelectr6Value: function setSelectr6Value(val) {
      this.row.r6 = val;
    },
    setSelectr7Value: function setSelectr7Value(val) {
      this.row.r7 = val;
    },
    setSelectr8Value: function setSelectr8Value(val) {
      this.row.r8 = val;
    },
    setSelectr9Value: function setSelectr9Value(val) {
      this.row.r9 = val;
    },
    setSelectr10Value: function setSelectr10Value(val) {
      this.row.r10 = val;
    },
    setSelectr11Value: function setSelectr11Value(val) {
      this.row.r11 = val;
    },
    setSelectr12Value: function setSelectr12Value(val) {
      this.row.r12 = val;
    },
    setSelectr13Value: function setSelectr13Value(val) {
      this.row.r13 = val;
    },
    setSelectr14Value: function setSelectr14Value(val) {
      this.row.r14 = val;
    },
    setSelectr15Value: function setSelectr15Value(val) {
      this.row.r15 = val;
    },
    setSelectr16Value: function setSelectr16Value(val) {
      this.row.r16 = val;
    },
    setSelectr17Value: function setSelectr17Value(val) {
      this.row.r17 = val;
    },
    setSelectr18Value: function setSelectr18Value(val) {
      this.row.r18 = val;
    },
    setSelectr19Value: function setSelectr19Value(val) {
      this.row.r19 = val;
    },
    setSelectr20Value: function setSelectr20Value(val) {
      this.row.r20 = val;
    },
    setSelectr21Value: function setSelectr21Value(val) {
      this.row.r21 = val;
    },
    setSelectr22Value: function setSelectr22Value(val) {
      this.row.r22 = val;
    },
    setSelectr23Value: function setSelectr23Value(val) {
      this.row.r23 = val;
    },
    setSelectr24Value: function setSelectr24Value(val) {
      this.row.r24 = val;
    },
    setSelectr25Value: function setSelectr25Value(val) {
      this.row.r25 = val;
    },
    setSelectr26Value: function setSelectr26Value(val) {
      this.row.r26 = val;
    },
    setSelectr27Value: function setSelectr27Value(val) {
      this.row.r27 = val;
    },
    setSelectr28Value: function setSelectr28Value(val) {
      this.row.r28 = val;
    },
    setSelectr29Value: function setSelectr29Value(val) {
      this.row.r29 = val;
    },
    setSelectr30Value: function setSelectr30Value(val) {
      this.row.r30 = val;
    },
    setSelectr31Value: function setSelectr31Value(val) {
      this.row.r31 = val;
    },
    setSelectr32Value: function setSelectr32Value(val) {
      this.row.r32 = val;
    },
    setSelectr33Value: function setSelectr33Value(val) {
      this.row.r33 = val;
    },
    setSelectr34Value: function setSelectr34Value(val) {
      this.row.r34 = val;
    },
    setSelectr35Value: function setSelectr35Value(val) {
      this.row.r35 = val;
    },
    setSelectr36Value: function setSelectr36Value(val) {
      this.row.r36 = val;
    },
    setSelectr37Value: function setSelectr37Value(val) {
      this.row.r37 = val;
    },
    setSelectr38Value: function setSelectr38Value(val) {
      this.row.r38 = val;
    },
    setSelectr39Value: function setSelectr39Value(val) {
      this.row.r39 = val;
    },
    setSelectr40Value: function setSelectr40Value(val) {
      this.row.r40 = val;
    },
    setSelectr41Value: function setSelectr41Value(val) {
      this.row.r41 = val;
    },
    setSelectr42Value: function setSelectr42Value(val) {
      this.row.r42 = val;
    },
    setSelectr43Value: function setSelectr43Value(val) {
      this.row.r43 = val;
    },
    setSelectr44Value: function setSelectr44Value(val) {
      this.row.r44 = val;
    },
    setSelectr45Value: function setSelectr45Value(val) {
      this.row.r45 = val;
    },
    setSelectr46Value: function setSelectr46Value(val) {
      this.row.r46 = val;
    },
    setSelectr47Value: function setSelectr47Value(val) {
      this.row.r47 = val;
    },
    setSelectr51Value: function setSelectr51Value(val) {
      this.row.r51 = val;
    },
    setSelectr52Value: function setSelectr52Value(val) {
      this.row.r52 = val;
    },
    setSelectr53Value: function setSelectr53Value(val) {
      this.row.r53 = val;
    },
    setSelectr54Value: function setSelectr54Value(val) {
      this.row.r54 = val;
    },
    setSelectr55Value: function setSelectr55Value(val) {
      this.row.r55 = val;
    },
    setSelectr56Value: function setSelectr56Value(val) {
      this.row.r56 = val;
    },
    setSelectr57Value: function setSelectr57Value(val) {
      this.row.r57 = val;
    },
    setSelectr58Value: function setSelectr58Value(val) {
      this.row.r58 = val;
    },
    setSelectr59Value: function setSelectr59Value(val) {
      this.row.r59 = val;
    },
    setSelectr60Value: function setSelectr60Value(val) {
      this.row.r60 = val;
    },
    setSelectr61Value: function setSelectr61Value(val) {
      this.row.r61 = val;
    },
    setSelectr62Value: function setSelectr62Value(val) {
      this.row.r62 = val;
    },
    setSelectr63Value: function setSelectr63Value(val) {
      this.row.r63 = val;
    },
    setSelectr64Value: function setSelectr64Value(val) {
      this.row.r64 = val;
    },
    setSelectr65Value: function setSelectr65Value(val) {
      this.row.r65 = val;
    },
    setSelectr66Value: function setSelectr66Value(val) {
      this.row.r66 = val;
    },
    setSelectr67Value: function setSelectr67Value(val) {
      this.row.r67 = val;
    },
    setSelectr68Value: function setSelectr68Value(val) {
      this.row.r68 = val;
    },
    setSelectr69Value: function setSelectr69Value(val) {
      this.row.r69 = val;
    },
    setSelectr70Value: function setSelectr70Value(val) {
      this.row.r70 = val;
    },
    setSelectr71Value: function setSelectr71Value(val) {
      this.row.r71 = val;
    },
    // 编辑反显
    editInit: function editInit() {
      var _this3 = this;

      var postParam = {
        id: this.row.id
      };

      _MaterialConfigService.default.getDetail(postParam).then(function (result) {
        var resDetail = result.data[0].details;
        resDetail.forEach(function (item) {
          var num = item.fieldName.split('_')[1];

          _this3.$set(_this3.row, 'r' + num, item.fileUuid);
        });

        _this3.getDefaultList();
      });
    },
    // 获取下拉列表内容  文本为1 图片为0
    getSelectList: function getSelectList() {
      var _this4 = this;

      var picParam = {
        childType: this.selectPicType
      }; // 图片

      _MaterialConfigService.default.getSelectList(picParam).then(function (result) {
        _this4.selectPictureList = result.data;
      });

      var textParam = {
        childType: this.selectTextType
      }; // 文本

      _MaterialConfigService.default.getSelectList(textParam).then(function (result) {
        _this4.selectTextList = result.data;
      });
    },
    // 设置默认值
    setPictureDefaultValue: function setPictureDefaultValue() {},
    // 获取默认值
    getDefaultList: function getDefaultList() {
      var _this5 = this;

      var postParam = {
        tempType: this.modeTypeCode
      };

      _MaterialConfigService.default.getModelDetail(postParam).then(function (result) {
        result.hasFailure(function () {
          if (_this5.row.id) {
            _this5.$message.warning(result.getErrorMessage("查询失败"));
          }
        }).hasSuccess(function () {
          var defaultList = result.data;
          var objs = _this5.row;

          if (!objs.id) {
            // 为新增先设置为null
            // 图片
            for (var i = 1; i <= 47; i++) {
              _this5.row['r' + i] = null;
            } // 文本


            for (var _i2 = 51; _i2 <= 71; _i2++) {
              _this5.row['r' + _i2] = null;
            }
          }

          var _loop = function _loop(key) {
            if (!objs[key]) {
              if (key.includes('r')) {
                var num = key.split('r')[1];
                var attrStr = "ITEM_" + num;
                defaultList.map(function (item) {
                  if (attrStr == item.fieldName) {
                    _this5.$set(_this5.row, key, item.fileUuid);

                    return;
                  }
                });
              }
            }
          };

          for (var key in objs) {
            _loop(key);
          } // fieldName
          // : 
          // "ITEM_1"
          // fileUuid
          // : 
          // "7d53bb7038c4430caf11383990422c70"
          // tempType
          // : 
          // 1
          // this.defaultList = []
          // resDetail.forEach(item=>{
          //   const num = item.fieldName.split('_')[1]
          //   this.$set(this.row,'r' + num,item.fileUuid)
          // })

        });
      });
    }
  },
  created: function created() {
    // 编辑反显初始化
    if (this.row.id) {
      this.editInit();
    } else {
      this.getDefaultList();
    }

    this.getSelectList();
  }
};
exports.default = _default2;