"use strict";

var _interopRequireDefault = require("E:/\u684C\u9762/company/anhui/fdm-product-manager-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.function.name.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _FileUpload = _interopRequireDefault(require("@/components/FileUpload"));

var _Enums = require("@/api/Enums");

var _FileUploadPicture = _interopRequireDefault(require("@/components/FileUploadPicture"));

var _convertImgUrlPrefix2 = _interopRequireDefault(require("@/utils/convertImgUrlPrefix"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    FileUpload: _FileUpload.default,
    FileUploadPicture: _FileUploadPicture.default
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      btnSaveLoading: false,
      rules: {},
      selectedRow: {
        unreachImg: undefined,
        reachImg: undefined,
        cardDetailImg: undefined
      },
      FileUploadUrl: _Enums.FileUploadUrl
    };
  },
  computed: {
    visibleDialog: {
      get: function get() {
        return this.visible;
      },
      set: function set(val) {
        this.$emit('update:visible', val);
      }
    },
    convertImgUrlPrefix: function convertImgUrlPrefix() {
      return function (url) {
        return (0, _convertImgUrlPrefix2.default)(url);
      };
    }
  },
  watch: {},
  methods: {
    handleCancel: function handleCancel() {
      this.$refs.rowForm.resetFields();
      this.visibleDialog = false;
    },
    handleSave: function handleSave() {
      this.btnSaveLoading = false;
      this.$message.success('保存成功');
      this.$emit('ok', this.selectedRow);
      this.handleCancel();
    },
    // 时间格式化
    formatTime: function formatTime(t) {
      var data = t;
      var dtime = new Date(data);
      var year = dtime.getFullYear();
      var month = dtime.getMonth() + 1;

      if (month < 10) {
        month = '0' + month;
      }

      var day = dtime.getDate();

      if (day < 10) {
        day = '0' + day;
      }

      return year + '-' + month + '-' + day;
    },
    reviewFileChange: function reviewFileChange(item) {
      if (item instanceof Array && item.length > 0) {
        this.selectedRow.unreachImg = {
          name: item[0].name,
          uname: item[0].uname,
          url: item[0].url,
          secUuid: item[0].secUuid,
          id: item[0].id
        };
      } else {
        this.selectedRow.unreachImg = undefined;
      }

      console.log(this.selectedRow.unreachImg);
    },
    reviewFileChangeSec: function reviewFileChangeSec(item) {
      if (item instanceof Array && item.length > 0) {
        this.selectedRow.reachImg = {
          name: item[0].name,
          uname: item[0].uname,
          url: item[0].url,
          secUuid: item[0].secUuid,
          id: item[0].id
        };
      } else {
        this.selectedRow.reachImg = undefined;
      }

      console.log(this.selectedRow.reachImg);
    },
    detailReviewFileChange: function detailReviewFileChange(item) {
      if (item instanceof Array && item.length > 0) {
        this.selectedRow.cardDetailImg = {
          name: item[0].name,
          uname: item[0].uname,
          url: item[0].url,
          secUuid: item[0].secUuid,
          id: item[0].id
        };
      } else {
        this.selectedRow.cardDetailImg = undefined;
      }

      console.log(this.selectedRow.cardDetailImg);
    },
    //上传图片回调
    posterReviewFileChange: function posterReviewFileChange(fileList) {
      var productArr;

      if (fileList.length > 0) {
        productArr = fileList;
      } else {
        productArr = [];
      }

      this.$set(this.selectedRow, 'posterImg', productArr);
      console.log("posterImg:::", this.selectedRow);
    } // 上传图片子组件返回按钮控制
    // saveBtnSuccess(val) {
    //   this.btnSaveLoading = val
    // }

  },
  created: function created() {
    if (this.row) {
      this.selectedRow = this.row;
    }
  }
};
exports.default = _default;